import React from 'react'

const AccountGreen: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.67141 36.4883H24.8634C26.8372 36.4883 28.4653 34.9627 28.5622 33.02L30.0238 3.82865C30.1281 1.74512 28.4415 0 26.325 0H8.18788C6.0696 0 4.38302 1.74695 4.48912 3.8323L5.97082 33.0237C6.0696 34.9663 7.69581 36.4901 9.66958 36.4901L9.67141 36.4883Z"
        fill="#00E100"
      />
      <path
        d="M11.4904 32.7546H23.0257C23.9824 32.7546 24.7855 31.9039 24.8806 30.7881L26.3715 6.0455C26.4812 4.75953 25.6196 3.64551 24.5166 3.64551H9.99957C8.89652 3.64551 8.03494 4.76136 8.14469 6.0455L9.63554 30.7881C9.73067 31.9039 10.5319 32.7546 11.4904 32.7546Z"
        fill="white"
      />
      <path
        d="M17.464 30.5452C18.4743 30.5452 19.2933 29.7263 19.2933 28.716C19.2933 27.7057 18.4743 26.8867 17.464 26.8867C16.4538 26.8867 15.6348 27.7057 15.6348 28.716C15.6348 29.7263 16.4538 30.5452 17.464 30.5452Z"
        fill="#00E100"
      />
      <path
        d="M15.3888 1.90984C14.3786 1.90984 13.5596 2.72882 13.5596 3.7391C13.5596 4.74937 14.3786 5.56836 15.3888 5.56836H19.137C20.1473 5.56836 20.9663 4.74937 20.9663 3.7391C20.9663 2.72882 20.1473 1.90984 19.137 1.90984L15.3888 1.90984Z"
        fill="#00E100"
      />
      <path
        d="M29.9767 38.7416C28.228 38.7416 26.8048 37.3184 26.8048 35.5696V33.6306H24.8658C23.117 33.6306 21.6938 32.2075 21.6938 30.4587C21.6938 28.7099 23.117 27.2867 24.8658 27.2867H26.8048V25.3477C26.8048 23.5989 28.228 22.1758 29.9767 22.1758C31.7255 22.1758 33.1487 23.5989 33.1487 25.3477V27.2867H35.0877C36.8365 27.2867 38.2596 28.7099 38.2596 30.4587C38.2596 32.2075 36.8365 33.6306 35.0877 33.6306H33.1487V35.5696C33.1487 37.3184 31.7255 38.7416 29.9767 38.7416Z"
        fill="white"
      />
      <path
        d="M29.9761 23.4305C31.0371 23.4305 31.8932 24.2866 31.8932 25.3476V28.5415H35.0871C36.1481 28.5415 37.0042 29.3976 37.0042 30.4585C37.0042 31.5195 36.1481 32.3756 35.0871 32.3756H31.8932V35.5695C31.8932 36.6305 31.0371 37.4866 29.9761 37.4866C28.9151 37.4866 28.0591 36.6305 28.0591 35.5695V32.3756H24.8652C23.8042 32.3756 22.9481 31.5195 22.9481 30.4585C22.9481 29.3976 23.8042 28.5415 24.8652 28.5415H28.0591V25.3476C28.0591 24.2866 28.9151 23.4305 29.9761 23.4305ZM29.9761 20.9189C27.5341 20.9189 25.5475 22.9055 25.5475 25.3476V26.0299H24.8652C22.4231 26.0299 20.4365 28.0165 20.4365 30.4585C20.4365 32.9006 22.4231 34.8872 24.8652 34.8872H25.5475V35.5695C25.5475 38.0116 27.5341 39.9981 29.9761 39.9981C32.4182 39.9981 34.4048 38.0116 34.4048 35.5695V34.8872H35.0871C37.5291 34.8872 39.5157 32.9006 39.5157 30.4585C39.5157 28.0165 37.5291 26.0299 35.0871 26.0299H34.4048V25.3476C34.4048 22.9055 32.4182 20.9189 29.9761 20.9189Z"
        fill="#00E100"
      />
    </svg>
  )
}

export default AccountGreen
